<script setup>
const props = defineProps({
  showDialog: {
    type: Boolean,
    default: false,
  },
  channel_info: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(["closeCreatedLinkDialog"]);

const closeDialog = () => {
  emit("closeCreatedLinkDialog");
};

const firstName = props.channel_info.channel_name.split(" ")[0];
const headingText = `Voila! Here's ${firstName}'s Channel Permanent Smart YouTube Link: Dive into the Content!`;
</script>

<template>
  <Dialog
    :header="headingText"
    :visible="showDialog"
    @update:visible="closeDialog"
    :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
    :modal="true"
    :dismissableMask="true"
    id="smartyt-link-created-dialog"
  >
    <div class="grid grid-cols-1 gap-1">
      <h3 v-if="!channel_info.is_claimed" class="text-center text-sm mb-10">
        Go Beyond the Basics! Claim this Link for Extended Customization Options!
      </h3>
      <CardStaticTwitter
        :link="props.channel_info.smartlnk"
        :linkTitle="channel_info.channel_name"
        :linkDescription="channel_info.channel_description"
        :linkImage="channel_info.channel_thumbnail_url"
        :openInNewTab="true"
      />
      <!-- copy and input -->
      <div class="mt-4">
        <CustomInputCopy :link="channel_info.smartlnk" />
      </div>
      <!-- claim part -->
      <div class="flex justify-between items-center mt-4">
        <SmartytLinkIsClaimed :is_claimed="channel_info.is_claimed" />
        <SmartytLinkClaimYoursBtn :smartlink="channel_info.smartlnk" />
      </div>
      <!-- share -->
      <CustomShareLinkRow
        :link="channel_info.smartlnk"
        :title="'Experience the Power of SmartYT Link - Stand Out with Customized Links!'"
        :description="'Elevate your YouTube channel with SmartYT Link! Create customized links that showcase your latest videos in style. Share your unique SmartYT Link and make a lasting impression. Visit smartyt.link to unlock the potential of your channel today!'"
        :quote="'Revolutionize the way you share links.'"
        :hashtags="'#smartlnks #smartytlink'"
      />
    </div>
  </Dialog>
</template>

<style lang="postcss">
#smartyt-link-created-dialog {
  @apply w-[80%] md:!w-[40%] lg:!w-[30%];
}
</style>
