<script setup>
const showDialog = ref(false);
function closeDialogCallback() {
  showDialog.value = false;
}
</script>
<template>
  <button
    class="lg:col-span-1 md:col-span-2 col-span-4 border rounded-xl cursor-pointer select-none text-center hover:scale-105"
    @click="showDialog = true"
  >
    <div class="flex justify-center items-center h-full">
      <div class="py-10 md:py-0">
        <i class="pi pi-plus text-4xl"></i><br />
        <span class="text-2xl font-medium text-center">Request New</span>
      </div>
    </div>
  </button>
  <SmartytLinkRequestNewDialog v-if="showDialog" @closeDialog="closeDialogCallback" />
</template>
