<script setup>
import { notifyError } from "~/utils/notifications";

useServerSeoMeta({
  // title: "Latest Videos from Top YouTube Channels | Search & Share",
  // ogTitle: "Latest Videos from Top YouTube Channels | Search & Share",
  title: "Claim Your Custom YouTube Permanent Link | Optimize Searchability and Sharing",
  ogTitle:
    "Claim Your Custom YouTube Permanent Link | Optimize Searchability and Sharing",
  description:
    "Discover the latest videos from top YouTube channels. Search, share, and stay updated with your favorite creators. All in one place!",
  ogDescription:
    "Discover the latest videos from top YouTube channels. Search, share, and stay updated with your favorite creators. All in one place!",
  ogImage: "https://smartlnks-assets.s3.ap-south-1.amazonaws.com/ytonelink-view.webp",
  twitterCard: "summary_large_image",
});

const apis = new APIS();
const loading = ref(false);

const request_uri = `${apis.BASE_URL + apis.GET_RECOMMENDED_SMART_YT_LINKS}`;
const options = {
  method: "GET",
  headers: {
    "Content-Type": "application/json",
  },
};

const { data, error, pending } = await useAsyncData(() => $fetch(request_uri, options));

if (error.value) {
  notifyError({
    title: "Failed to fetch recommended smart yt links",
    text:
      "Sorry, an error occurred while processing your request. Please refresh the page and try again. If the problem persists, please contact our support team for assistance.",
  });
}

function updateLinksCallback(updated_links) {
  data.value = updated_links;
}

function loadingCallback(value) {
  loading.value = value;
}
</script>

<template>
  <div class="bg-white relative">
    <div class="flex justify-center">
      <div class="pt-44 flex items-center justify-center w-3/4">
        <div>
          <h1 class="md:text-4xl text-2xl font-extrabold mt-2">
            Discover YouTube Permanent Link: Your Shortcut to the Latest Videos!
          </h1>
          <p class="my-4 font-medium text-center">
            Seamless Viewing Experience in the YouTube App
          </p>
        </div>
      </div>
    </div>

    <div class="mt-16 flex justify-center">
      <SmartytLinkSearchField
        @updateLinks="updateLinksCallback"
        @loading="loadingCallback"
      />
    </div>

    <div class="mt-20">
      <div v-if="pending || loading" class="loading-overlay fixed">
        <LoadersRingLoader />
      </div>
      <SmartytLinkCreatorCardGrid v-if="data.data?.length > 0" :links="data.data" />
      <SmartytLinkNoLinkFound v-else-if="!pending || !loading" />
    </div>
    <div class="py-20"></div>
  </div>
</template>

<style lang="postcss">
#request-new-smartyt-link-btn {
  @apply py-2 px-4 !bg-green-600 !border-green-600;
}
</style>
