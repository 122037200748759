<script setup>
import { required, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import { notifyError } from "~/utils/notifications";
import { removeAllParams } from "~/utils/urls";

const emit = defineEmits(["closeDialog"]);

const { sendPostRequest } = useSendRequest();

const closeDialog = () => emit("closeDialog");

const youtubeUrlRegex = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;

const responseError = ref();

const showCreatedLinkDialog = ref(false);

const form = reactive({
  video_url: "",
});

const formRules = {
  video_url: {
    required: helpers.withMessage("Video URL is required", required),
    isYouTubeUrl: helpers.withMessage("Invalid Video URL", (value) => {
      return value.length > 0 ? youtubeUrlRegex.test(value) : true;
    }),
  },
};
const formV$ = useVuelidate(formRules, form);

const isSubmitted = ref(false);
const showLoader = ref(false);
const channel_info = ref();

const apis = new APIS();

async function onSubmitted(isFormValid) {
  isSubmitted.value = true;

  if (isFormValid) {
    return;
  }

  showLoader.value = true;

  const url = `${apis.BASE_URL + apis.REQUEST_SMART_YT_LINK}`;
  const headers = {
    "Content-Type": "application/json",
  };
  const body = {
    video_url: form.video_url,
  };
  const response = await sendPostRequest(url, headers, body);
  if (response.status === 201 || response.status === 302) {
    resetForm();
    channel_info.value = response.data?.data;
    showCreatedLinkDialog.value = true;
  } else {
    responseError.value = response.data?.message;
    notifyError();
  }

  showLoader.value = false;
}

function resetForm() {
  form.video_url = "";
  isSubmitted.value = false;
}

function closeCreatedLinkDialogCallback() {
  showCreatedLinkDialog.value = false;
  closeDialog();
}
</script>

<template>
  <Dialog
    header="Request a Personalized SmartYT Link!"
    :visible="true"
    @update:visible="closeDialog"
    :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
    :modal="true"
    :dismissableMask="true"
    id="request-new-smartyt-link-dialog"
  >
    <div class="grid grid-cols-1 gap-1">
      <h3 class="text-center text-sm mb-10">
        Get Instant Access to Your Favorite Creator's Latest Videos with a Custom SmartYT Link!
      </h3>

      <CustomFieldText
        :Field="formV$.video_url"
        :isSubmitted="isSubmitted"
        :label="'Video URL'"
        :inputId="'yt-video-url-input-field'"
        :placeholder="'Enter channel any video url'"
        :helpText="'eg. https://www.youtube.com/watch?v=UrIaQbIK2E4'"
      />

      <Button
        class=""
        @click="onSubmitted(formV$.$invalid)"
        :label="
          showLoader ? 'Taking a Moment to Create Something Extraordinary...' : 'Submit'
        "
        :loading="showLoader"
      />
    </div>
  </Dialog>
  <SmartytLinkCreatedLinkDialog
    v-if="showCreatedLinkDialog"
    :showDialog="showCreatedLinkDialog"
    :channel_info="channel_info"
    @closeCreatedLinkDialog="closeCreatedLinkDialogCallback()"
  />
</template>

<style lang="postcss">
#request-new-smartyt-link-dialog {
  @apply w-[80%] md:!w-[40%] lg:!w-[30%];
}
</style>
