<script setup>
import { useDebounceFn } from "@vueuse/core";
import { notify } from "notiwind";

const emit = defineEmits(["loading", "updateLinks"]);

const apis = new APIS();
const selectedOption = ref();
const options = ref([
  { name: "Latest", code: "latest" },
  { name: "Random", code: "random" },
]);

const searchInput = ref();
const loading = ref(false);

const onSearchInputChange = useDebounceFn(() => {
  // do something
  loading.value = false;
  if (searchInput.value.length > 0) searchInputVal(searchInput.value);
  else searchInputVal(undefined);
}, 1000);

async function searchInputVal(val) {
  emit("loading", true);
  loading.value = true;

  const request_uri = `${apis.BASE_URL + apis.SEARCH_SMART_YT_LINK}`;

  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    params: {
      q: val,
    },
  };

  const { data: allLinks, error } = await useAsyncData(() =>
    $fetch(request_uri, options)
  );

  if (error.value) {
    if (error.value.statusCode !== 404)
      notify(
        {
          group: "top",
          title: "Failed to search YouTube creator!",
          text:
            "Sorry, an error occurred while processing your request. Please refresh the page and try again. If the problem persists, please contact our support team for assistance.",
          severity: "error",
        },
        7000
      ); // 7s
  } else if (allLinks.value) {
    emit("updateLinks", allLinks.value);
  }

  loading.value = false;
  emit("loading", false);
}
</script>

<template>
  <div class="grid grid-cols-12 items-center w-[95%] md:w-3/6">
    <!-- <div class="col-span-0"> -->
    <!-- <Dropdown
            v-model="selectedOption"
            :options="options"
            optionLabel="name"
            placeholder="Select an Option"
            class="w-full md:w-14rem p-1"
          /> -->
    <!-- </div> -->
    <div class="col-span-12">
      <div
        class="relative w-full flex items-center justify-center border-2 rounded-full p-1"
      >
        <div
          class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"
        ></div>
        <input
          type="search"
          id="search"
          class="block w-full p-5 pl-5 text-base text-gray-900 border border-gray-300 rounded-full bg-gray-50 focus:ring-black focus:border-black dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-black dark:focus:border-black"
          placeholder="Search for your favorite creator's channel..."
          required
          v-model.trim="searchInput"
          @keyup="onSearchInputChange()"
          :disabled="loading"
        />
        <Button id="search-smartyt-link" :loading="loading">
          <i class="pi pi-search mr-2"></i>
          Search
        </Button>
      </div>
    </div>
  </div>
</template>

<style lang="postcss">
#search-smartyt-link {
  @apply text-white absolute right-2.5 bottom-2.5 top-[9px] !bg-black hover:bg-black focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-base px-7 py-3 dark:bg-black dark:hover:bg-black dark:focus:ring-black border-none;
}
</style>
