<script setup>
import { Vue3Lottie } from 'vue3-lottie'
import notFound from "~~/lottie/not-found.json";

const showDialog = ref(false);
function closeDialogCallback() {
  showDialog.value = false;
}
</script>

<template>
  <div class="text-center">
    <Vue3Lottie :animationData="notFound" :height="220" />
    <p class="my-10 text-lg font-semibold">YouTube One Link Not Found</p>
    <Button
      label="Request New"
      @click="showDialog = true"
      class="!py-2 !px-5 hover:scale-105"
    />

    <SmartytLinkRequestNewDialog v-if="showDialog" @closeDialog="closeDialogCallback" />
  </div>
</template>

<style></style>
