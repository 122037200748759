<script setup>
const props = defineProps({
  smartlink: {
    type: String,
    required: true,
  },
});
const showDialog = ref(false);
function closeDialogCallback() {
  showDialog.value = false;
}
</script>

<template>
  <Button id="claim-yours-btn" label="claim yours!" @click="showDialog = true" />
  <SmartytLinkClaimYoursDialog
    v-if="showDialog"
    :smartlink="smartlink"
    @closeDialog="closeDialogCallback"
  />
</template>

<style lang="postcss">
#claim-yours-btn {
  @apply !py-2;
}
#claim-yours-btn > .p-button-label {
  @apply !text-xs !font-bold;
}
</style>
