<script setup>
import { required, email, minLength, helpers, maxLength } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import { notifyError, notifySuccess } from "~/utils/notifications";

const props = defineProps({
  smartlink: {
    type: String,
    required: true,
  },
});
const emit = defineEmits(["closeDialog"]);

const { sendPostRequest } = useSendRequest();

const apis = new APIS();

function closeDialog() {
  emit("closeDialog");
}
const isValidInstagramHandle = (username) => {
  const regex = /^[a-zA-Z0-9._]{1,30}$/;
  return regex.test(username);
};

const form = reactive({
  instagram_handle: "",
  email: "",
});

const formRules = {
  instagram_handle: {
    required: helpers.withMessage("Instagram Handle is required", required),
    minLength: helpers.withMessage(
      "Instagram must be at least 3 characters",
      minLength(3)
    ),
    maxLength: helpers.withMessage(
      "Instagram must be at less than 30 characters",
      maxLength(30)
    ),
    isValidInstagramHandle: helpers.withMessage("Invalid Instagram Handle", (value) => {
      return value.length > 0 ? isValidInstagramHandle(value) : true;
    }),
  },
  email: {
    required: helpers.withMessage("Email is required", required),
    email: helpers.withMessage("Invalid Email", email),
    minLength: helpers.withMessage("Email must be at least 5 characters", minLength(5)),
  },
};
const formV$ = useVuelidate(formRules, form);

const isSubmitted = ref(false);
const showLoader = ref(false);

async function onSubmitted(isFormValid) {
  isSubmitted.value = true;

  if (isFormValid) {
    return;
  }

  showLoader.value = true; // show loader

  const url = `${apis.BASE_URL + apis.CLAIM_SMART_YT_LINK}`;
  const headers = {
    "Content-Type": "application/json",
  };
  const body = {
    smartlnk: props.smartlink,
    instagram_handle: form.instagram_handle,
    email: form.email,
  };

  const response = await sendPostRequest(url, headers, body);
  if (response.status === 200) {
    notifySuccess(response.data?.data?.details);
    closeDialog();
  } else {
    notifyError({ title: response.data?.message });
  }

  resetForm();
  showLoader.value = false;
}

function resetForm() {
  form.email = "";
  form.instagram_handle = "";
}
</script>
<template>
  <Dialog
    header="Claim Your SmartYT Link and Unlock the Potential of Your YouTube Channel!"
    :visible="true"
    @update:visible="closeDialog"
    :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
    :modal="true"
    :dismissableMask="true"
    id="claim-smartyt-link-dialog"
  >
    <div class="grid grid-cols-1 gap-1">
      <h3 class="text-center text-sm mb-10">
        Customize, Share, and Stand Out with SmartYT Link - Your Gateway to YouTube Success!
      </h3>

      <CustomFieldText
        :Field="formV$.instagram_handle"
        :isSubmitted="isSubmitted"
        :label="'Instagram Handle'"
        :inputId="'instagram-handle-input-field'"
        :placeholder="'Enter your instagram handle'"
        :helpText="'do not include &quot;@&quot; e.g. smartlnks.com_'"
      />
      <CustomFieldText
        :Field="formV$.email"
        :isSubmitted="isSubmitted"
        :label="'Email'"
        :inputId="'claim-request-email-input-field'"
        :placeholder="'Enter your email address'"
        :helpText="'eg. team@smartlnks.com'"
      />

      <p class="mb-8 text-sm text-gray-500">
        Please note that providing false information will result in the cancellation of
        your request. Therefore, kindly ensure that you enter your original details
        accurately.
      </p>

      <Button
        class=""
        @click="onSubmitted(formV$.$invalid)"
        label="
            Claim Now!
        "
        :loading="showLoader"
      />
    </div>
  </Dialog>
</template>

<style lang="postcss">
#claim-smartyt-link-dialog {
  @apply w-[80%] md:!w-[40%] lg:!w-[30%];
}
</style>
