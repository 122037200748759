<script setup>
const props = defineProps({
  smartlink: {
    type: String,
    required: true,
  },
  video_id: {
    type: String,
    required: true,
  },
  channel_name: {
    type: String,
    required: true,
  },
  channel_description: {
    type: String,
    required: false,
  },
  channel_logo: {
    type: String,
    required: true,
  },
  is_claimed: {
    type: Boolean,
    required: true,
  },
});
</script>

<template>
  <div class="mt-8 hover:scale-105">
    <CardStaticTwitter
      :link="props.smartlink"
      :linkTitle="channel_name"
      :linkDescription="channel_description"
      :linkImage="channel_logo"
      :openInNewTab="true"
    />
    <!-- copy and input -->
    <div class="mt-4">
      <CustomInputCopy :link="smartlink" />
    </div>
    <!-- claim part -->
    <div class="flex justify-between items-center mt-4">
      <SmartytLinkIsClaimed :is_claimed="is_claimed" />
      <SmartytLinkClaimYoursBtn v-if="!is_claimed" :smartlink="smartlink" />
    </div>
    <!-- share -->
    <CustomShareLinkRow
      :link="smartlink"
      :title="'Experience the Power of SmartYT Link - Stand Out with Customized Links!'"
      :description="'Elevate your YouTube channel with SmartYT Link! Create customized links that showcase your latest videos in style. Share your unique SmartYT Link and make a lasting impression. Visit smartyt.link to unlock the potential of your channel today!'"
      :quote="'Revolutionize the way you share links.'"
      :hashtags="'#smartlnks #smartytlink'"
    />
  </div>
</template>

<style></style>
